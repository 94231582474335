.error_container {
  height: 100dvh;
  max-height: 100dvh;
  width: 100%;
  background: var(--background-image);
  background-size: cover;
  background-position: center right;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  line-height: 1.5;
  overflow: hidden;

  .error_info {
    margin: 2rem;
  }

  h1 {
    font-size: 2.5rem;
  }
}

.buttons {
  display: flex;
  gap: .5rem;
  margin-top: 2.5rem;
}