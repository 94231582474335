
.button_link {
  --transition-ms: 150ms;
  background: var(--bg-color);
  text-decoration: none;
  padding: .75rem 1.5rem;
  border-radius: var(--rounded-full);
  color: var(--color);
  font-weight: normal;
  display: inline-flex;
  transition: var(--transition-ms);
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  border-bottom: none;

  > div {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.display_block {
    display: flex;
  }

  &.center {
    justify-content: center;
  }

  &:hover {
    background: var(--hover-bg-color);
    box-shadow: var(--box-shadow);
    transform: scale(1.15);
    cursor: pointer;
    border-bottom: none;

    .icon_container {
      opacity: 1;
      transition-delay: .05s;
    }

    .label {
      padding-left: 1.5rem;

      &.no_hover_padding {
        padding-left: 0;
      }
    }
  }

  &.no_scale {
    &:hover {
      transform: scale(1);
      box-shadow: none;
    }
  }

  &:active {
    transform: scale(.9);
    background: rgb(var(--color-ci-green));
    transition: 50ms;
  }
}

.icon_container {
  position: absolute;
  opacity: 0;
  transition: var(--transition-ms);
}

.label {
  transition: var(--transition-ms);
}