
.container {
  
  overflow:hidden;
  white-space: nowrap;
  display: flex;

  .beginning{
    text-overflow: ellipsis;
    overflow: hidden;
  } 

  &.inline {
    display:inline-flex;
  }

  .end {
    padding-left: 0.5ch;
  }
}