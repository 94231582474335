.header_container {
  height: 65px;
  color: white;
  position: fixed;
  width: 100%;
  z-index: 15;
  display: flex;
  align-items: center;
  padding: .1rem 1.2rem 0;
  background: transparent;
  transition: background .3s;

  &.scrolled {
    background: rgba(0,0,0,.9);
  }
}

.logo {
  display: inline-block;
  width: 10rem;
  border: none;
  

  img {
    height: 100%;
  }
}

.header_rest {
  flex: 1;
  display: flex;
  justify-content: right;
}