@import '../../css-global/break-points.scss';

.hero_container {
  position: relative;
  margin-bottom: var(--brand-color-space);

}

.image_container {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vw), 0% 100%);
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100%;
  min-height: var(--min-height);
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0,0,0, .7);
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: var(--color-alpha);
  }
}

.brand_color {
  background: var(--color-solid);
  height: 100%;
  min-height: var(--min-height);
  position: absolute;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 7vw));
  z-index: 0;
  box-sizing: content-box;
}

.hero_content {
  position: relative;
  z-index: 2;
  color: white;
  margin: auto;
  max-width: 1200px;
  padding: 6rem 0 8rem;

  @media screen and (min-width: map-get($bp, lg)) {
    padding: 6rem 0 10rem;
  }

  @media screen and (min-width: map-get($bp, xxl)) {
    padding: 6rem 0 12rem;
  }

  h1 {
    text-align: center;
    opacity: 1;
  }

  > div {
    &:first-child {
      margin: auto;
      
      div {
        margin: auto;
      }
    }
  }
}

.invitation {
  h1 {
    opacity: 0;
    @media screen and (min-width: map-get($bp, md)) {
      text-align: center;
      opacity: 1;
    }
  }
}

.buttons {
  display: flex;
  gap: .5rem;
  flex-wrap: wrap;
  justify-content: center;
}

.invitation {
  .buttons {
    display: none;
  
    @media screen and (min-width: map-get($bp, md)) {
      display: flex;
      gap: .5rem;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.hero_inner_container {
  width: 100%;
}

.logo_transition_container {
  transition: var(--timeout);
  display: none;

  &.organizer {
    display: block;
    transform: scale(1);
    height: 180px;
    opacity: 1;
  }

  //ENTER
  &.enter {
    display: block;
    transform: scale(0);
    height: 0;
    opacity: 0;
  }
  &.enterActive {
    display: block;
    transform: scale(1.1);
    height: 180px;
    opacity: .9;
  }
  &.enterDone {
    display: block;
    transform: scale(1);
    height: 180px;
    opacity: 1;
  }

  // EXIT
  &.exit {
    display: block;
    transform: scale(1);
    height: 180px;
    opacity: 1;
  }
  &.exitActive {
    display: block;
    transform: scale(0);
    height: 0;
    opacity: 0;
  }
  &.exitDone {
    display: none;
  }
}

.hero_content_text_inner {
  margin: 0 auto 2rem;
  text-align: center;
  width: 100%;
  max-width: 800px;
  line-height: 1.5;
}

.hero_content_text {
  padding: 0 1rem;

  @media screen and (min-width: map-get($bp, sm)) {
    padding: 0 3rem;
  }

  h1 {
    &.extra_large {
      font-size: 1.5rem;

      @media screen and (min-width: map-get($bp, xs)) {
        font-size: 2rem;
      }

      @media screen and (min-width: map-get($bp, sm)) {
        font-size: 4rem;
      }

      @media screen and (min-width: map-get($bp, lg)) {
        font-size: 6rem;
      }
    }
  }
}