.menu_container {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: right;
    li {
      display: inline-block;
      position: relative;
      a {
        color: white;
        text-decoration: none;
        transition: .2s;
        &:hover {
          color: rgb(var(--color-ci-green-light));
        }
      }
      ul {
        display: none;
        position: absolute;
        right: -4px;
        width: 260px;
        margin-top: 1px;
        background: rgba(0,0,0,.9);
        padding: 1rem;
        border-radius: 5px;
        li {
          display: block;
          a {
            color: white;
            text-decoration: none;
            transition: .2s;
            &:hover {
              color: rgb(var(--color-ci-green-light));
            }
          }
        }
      }
      &:hover ul {
        display: block;
      }
    }
  }

  .activelink {
    color: green;
  }
}
.menu_container_mobile {
  display:none;
}

/* mobile */
@media screen and (max-width: 980px) {
  .menu_container {
    display:none;
  }
  .menu_container_mobile {
    display: block;
    position: relative;
    input {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;
      cursor: pointer;
  
      opacity: 0; /* hide this */
      z-index: 5; /* and place it over the hamburger */
      
      -webkit-touch-callout: none;
    }

    /*
    * Just a quick hamburger
    */
    span {
      display: block;
      width: 32px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      
      background: #fff;
      border-radius: 3px;
      
      z-index: 4;
      
      transform-origin: left;
      
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  opacity 0.55s ease;
    }

    span:first-child {
      transform-origin: 0% 0%;
    }

    span:first-child+span {
      transform-origin: 0% 100%;
    }

    /* 
    * Transform all the slices of hamburger
    * into a crossmark.
    */
    input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-3px, 0px);
      background: #fff;
    }

    /*
    * But let's hide the middle one.
    */
    input:checked + span+span {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    /*
    * Ohyeah and the last one should go the other direction
    */
    input:checked +span+span+span {
      transform: rotate(-45deg) translate(-3px, 0px);
    }

    ul#menu {
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      width: 50vw;
      height: 100vh;
      background: rgba(0,0,0,.9);
      margin:0;
      padding: 70px 30px;
      transform: translateX(100%);
      transition: all .3s;
      z-index: 2;

      li {
        display: block;
        margin-bottom: 1rem;
        a {
          color: white;
          text-decoration: none;
          transition: .2s;
          &:hover {
            color: rgb(var(--color-ci-green-light));
          }
        }
      }
      .activelink {
        color: green;
      }
    }
    input:checked ~ ul#menu {
      transform: translateX(0);
    }

    .overlay {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0,0,0,.5);
      z-index: 1;
      cursor: pointer;
    }
    input:checked ~ .overlay {
      display: block;
    }
  }
}