@import '../../css-global/break-points.scss';

.cards_list_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, min(380px, 100%));
  row-gap: 1rem;
  column-gap: 1rem;
  justify-items: center;
  justify-content: center;

  @media screen and (min-width: map-get($bp, sm)) {
    margin: 0 2rem;
  }

  @media screen and (min-width: map-get($bp, lg)) {
    margin: 0 0 4rem 0;
  }
}

.title {
  text-align: center;
  margin-bottom: 1.5rem;

  span {
    display: inline-block;
    background: rgba(255, 255, 255, .8);
    padding: 8px 20px;
    border-radius: var(--rounded);

    @media screen and (min-width: map-get($bp, lg)) {
      padding: 0;
      background: transparent;
    }
  }
}