@import '../../css-global/break-points.scss';

.search_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.close_button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  padding: .25rem 1rem;
  font-size: 1rem;
  border-radius: var(--rounded-full);
  background: transparent;
  font-weight: 100;
  color: white;
  border: 0;
  display: flex;
  align-items: center;
  gap: .5rem;
  transition: ease .2s;

  &:hover {
    background: white;
    color: black;
  }
}

.close_icon {
  font-size: 1.5rem;
}

.search_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 280px;
  max-width: 70vw;
  width: 100%;

  @media screen and (min-width: map-get($bp, xl)) {
    max-width: 40vw;
  }

  label {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    text-align: center;
  }
}

.search_input_container {
  position: relative;
  width: 100%;

  input {
    box-sizing: border-box;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-weight: 200 !important;
    display: block;
    background: transparent;
    border-bottom: 1px solid #fff;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding: .5rem .5rem .5rem 2.5rem;
    width: 100%;
  }
}

.search_icon {
  color: #fff;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: .5rem;
}

.search_button {
  background: rgb(var(--color-ci-green));
  color: white;
  font-weight: 700;
  font-size: 1rem;
  padding: .75rem 1.5rem;
  border: 2px solid white;
  border-radius: var(--rounded-full);
  margin-top: 1rem;
  transition: ease .15s;
  cursor: pointer;

  &:hover {
    background: white;
    color: black;
  }
}

.open_search_modal_button {
  font-size: 1rem;
  background: rgb(var(--color-ci-green));
  color: white;
  border: 0;
  padding: .5rem 1rem;
  border-radius: var(--rounded-full);
  cursor: pointer;

  &:hover {
    transition: ease 150ms;
    background: white;
    color: black;
  }
}

.search_btn_text {
  &:not(.always_show) {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;

    @media screen and (min-width: map-get($bp, xs)) {
      position: static;
      width: auto;
      height: auto;
      overflow: visible;
      clip: auto;
      white-space: normal;
      border: 0;
      margin: 0;
      padding: 0;
      -webkit-clip-path: none;
      clip-path: none;
    }
  }
}