@import '../../css-global/break-points.scss';

.button_link {
  --transition-ms: 100ms;
  background: var(--bg-color);
  text-decoration: none;
  border-radius: var(--rounded-full);
  color: var(--color);
  font-size: 1rem;
  font-weight: normal;
  display: inline-block;
  transition: var(--transition-ms);
  position: relative;
  white-space: nowrap;
  padding: .5rem 1rem;
  border: 3px solid white;
  font-weight: 700;

  @media screen and (min-width: map-get($bp, sm)) {
    padding: .75rem 1.5rem;
    font-size: 1.2rem;
  }

  &.got_icon {
    &:hover {
      .label {
        padding-left: 1.75rem;
      }
    }
  }

  &:hover {
    background: white;
    color: black;

    .icon_container {
      opacity: 1;
      transition-delay: .1s;
    }
  }

  &:active {
    background: white;
    transform: scale(.9);
    transition: 30ms;
  }
}


.icon_container {
  position: absolute;
  opacity: 0;
  transition: var(--transition-ms);
}

.label {
  transition: var(--transition-ms);
}