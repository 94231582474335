@import '../../css-global/break-points.scss';

.info_container {
  margin-bottom: 4rem;
  background: white;
  border-radius: var(--rounded-lg);
  padding: 2rem;
  background: var(--bg-color); 
  h1 {
    margin-top: 0;
  }

  @media screen and (min-width: map-get($bp, md)) {
    display: none;
  }
}