@use "variables.scss";
@import "break-points.scss";

html, 
body, 
#root {
  min-height: 100dvh;
}

body, button {
  margin: 0;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  font-family: Roboto, sans-serif;
}

body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.5rem
}

h3 {
  font-size: 1.25rem
}

.no_select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}

.body_link {
  color: rgb(var(--color-ci-blue));
  text-decoration: none;
  border-bottom: 1px solid rgb(var(--color-ci-blue));
  transition: border .15s;
  box-sizing: content-box;

  &.truncate {
    display: block;
    width:fit-content;
    max-width: 100%;
  }

  &:hover {
    border-bottom: 1px solid rgba(var(--color-ci-blue), 0);
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.App {
  display: flex;
  flex-direction: column;

  > div {
    &:last-child {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}

.max-75 {
  max-width:  75rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.max-50 {
  max-width:  50rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

// React Modal handling
.ReactModal__Overlay {
  z-index: 20;
  background-color: transparent !important;
}

.ReactModal__Content {
  inset: 0 !important;
  background: rgba(0,0,0, .9) !important;
  color: white !important;
}

.ReactModal__Body--open {
  max-height: 100dvh;
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.no_overflow {
  max-height: 100dvh;
  overflow: hidden;
}

#aria-live {
  position: absolute;
  left: -9999px;
  top: -9999px;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

@keyframes loadingBottom {
  0% {
    width: 5%;
  }
  20% {
    width: 100%;
  }
  21% {
    width: 3%;
  }
  70% {
    width: 100%;
  }
  71% {
    width: 10%;
  }
  100% {
    width: 100%;
  }
}

.loading-bottom {
  height: 7px;
  position: fixed;
  bottom: 0;
  background: rgba(102, 162, 218, .6);
  left: 0;
  animation: loadingBottom 1s infinite;
  z-index: 200;
}