:root {
  --color-ci-green: 0, 138, 25;
  --color-ci-green-lighter: 59, 152, 75;
  --color-ci-green-light: 60, 210, 105;
  --color-ci-blue: 52, 73, 97;
  --color-ci-red: 210, 65, 25;

  --color-tag-purple: 116, 55, 95;
  --color-tag-blue: var(--color-ci-blue);

  --color-gray-1: 240, 240, 240; /* lightest gray */
  --color-gray-2: 229, 229, 229;
  --color-gray-3: 204, 204, 204;
  --color-gray-4: 178, 178, 178;
  --color-gray-5: 153, 153, 153;
  --color-gray-6: 127, 127, 127;
  --color-gray-7: 102, 102, 102;
  --color-gray-8: 76, 76, 76;
  --color-gray-9: 51, 51, 51;
  --color-gray-10: 26, 26, 26;

  --color-footer-gray: 53, 53, 53;
  --color-footer-button: 43, 112, 34;

  --content-padding: 1rem;

  --rounded-lg: 1.8rem;
  --rounded-lg-t: 1.8rem 1.8rem 0 0;
  --rounded-lg-b: 0 0 1.8rem 1.8rem;
  --rounded: 1rem;
  --rounded-full: 5000px;

  --box-shadow: 0px 4px 8px 0px rgba(155, 155, 155, .4), 0px 0px 6px 0px rgba(220, 220, 220, .4);
  --box-shadow-lg: 0px 8px 16px 0px rgb(220, 220, 220), 0px 0px 8px 0px rgba(220, 220, 220, .4);
  --box-shadow-inverted: 0px -4px 4px 0px rgba(0,0,0, .05);
  --glow-white: 0px 0px 14px 0px rgba(255,255,255, .9);
  --glow-black: 0px 0px 5px 0px rgba(0,0,0, .5);

  --box-shadow-card: 0px 4px 8px 0px rgba(0, 0, 0, .1);
  --box-shadow-card-lg: 0px 12px 24px 0px rgba(0, 0, 0, .3);
  --box-shadow-loading: 0px 0px 4px 0px rgba(0, 0, 0, .2);
}