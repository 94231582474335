

.card_fill {
  --card-width: 380px;

  height: var(--card-height);
  position: relative;
  max-width: var(--card-width);
  width: 100%;
  min-width: 320px;
  margin-bottom: 2rem;
}

.card_container {
  position: absolute;
  top: 0;
  background: white;
  border-radius: var(--rounded-lg);
  box-shadow: var(--box-shadow-card);
  display: flex;
  flex-direction: column;
  border: 0;
  padding: 0;
  transition: var(--transition-time);
  text-align: left;
  font-size: 1rem;
  max-width: var(--card-width);
  width: 100%;
  transform-origin: center 220px;
  z-index: var(--z-index);
  cursor: pointer;
  color: black;

  &:hover{
    .image_container,
    .organizer_top {
      filter: brightness(1.3);  
    }
  }

  &.opened {
     /* Set the origin of the transformation */
    transform: scale(1.085);
    box-shadow: var(--box-shadow-card-lg);

    .image_container {
      filter: brightness(1.3);  
    }
  }
}

.invitation_img {
  max-height: 100%;
  max-width: 100%;
}

.image_container {
  height: 173px;
  overflow: hidden;
  border-radius: var(--rounded-lg-t);
  background: var(--event-background);
  background-size: cover;
  background-position: center;
  width: 100%;
  transition: 500ms;
  filter: none;

  h2 {
    margin: 0;
  }
}

.darken {
  height: 100%;
  background: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(0,0,0,0.9) 80%);
}

.backdrop_layer {
  //backdrop-filter: blur(20px);
  background: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.card_meta_data {
  display: flex;
  font-size: .9rem;
  gap: 10px;
  align-items: center;
  margin: 0;
  background: rgb(var(--color-gray-1));
  padding: .5rem;
  width: 100%;

}

.card_title {
  margin: 20px 0 0;

  > span {
    &:first-child {
      display: flex;
    }
    &:last-child {
      display: none;
    }
  }

  &.opened {
    > span {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: block;
      }
    }
  }
}

.card_content_visible {
  padding: 0 1rem 1rem;
  max-width: 100%;
}

.card_content_invisible {
  display: block;
  padding: 0 1rem 1rem 1rem;
  display: none;
  width: 100%;
  transition: var(--transition-time);
  max-height: 0;
  transform-origin: top center;

  &.enter {
    display: block;
    transform: scaleY(0);
    opacity: 0;
    max-height: 0;
    margin-top: -20px;
  }
  &.enterActive {
    display: block;
    transform: scaleY(1);
    opacity: .9;
    max-height: 2000px;
    margin-top: 0;
  }
  &.enterDone {
    display: block;
    transform: scaleY(1);
    opacity: 1;
    max-height: 2000px;
  }

  // EXIT
  &.exit {
    display: block;
    transform: scaleY(1);
    opacity: 1;
    max-height: 2000px;
  }
  &.exitActive {
    display: block;
    transform: scaleY(0);
    opacity: 0;
    max-height: 0;
  }
  &.exitDone {
    display: none;
  }
}

.classes_expand_button {
  border-radius: 2rem;
  border: 2px solid rgb(var(--color-ci-blue));
  padding: .5rem 1rem;
  font-size: 1rem;
  transition: var(--transition-time);

  &:hover {
    background: rgb(var(--color-ci-blue));
    color: #fff;
  }
}

.classes_header {
  margin-bottom: 1rem;
}

.class_list {
  display: none;
  width: 100%;
  transition: var(--transition-time);
  max-height: 0;
  transform-origin: top center;

  &.enter {
    display: block;
    transform: scaleY(0);
    opacity: 0;
    max-height: 0;
    margin-top: -20px;
  }
  &.enterActive {
    display: block;
    transform: scaleY(1);
    opacity: .9;
    max-height: 2000px;
    margin-top: 0;
  }
  &.enterDone {
    display: block;
    transform: scaleY(1);
    opacity: 1;
    max-height: 2000px;
  }

  // EXIT
  &.exit {
    display: block;
    transform: scaleY(1);
    opacity: 1;
    max-height: 2000px;
  }
  &.exitActive {
    display: block;
    transform: scaleY(0);
    opacity: 0;
    max-height: 0;
  }
  &.exitDone {
    display: none;
  }
}

.card_img_wrapper {
  height: 35%;
  border-radius: 7px;
  background: white;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;

  img {
    border-radius: 5px;
  }
}

.card_top_header {
  font-family: var(--font-header), sans-serif;
  color: white;
  text-transform: var(--font-header-transform);
  margin: .5rem 0 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dates {
  margin: 1rem 0;
}

.contact_person {
  > div {
    margin-top: 0.5rem;
  }
}

.image_date {
  margin: 0;
  font-size: 14px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
}

.image_text_container {
  text-align: center;
  max-width: 90%;
}

.card_section_header {
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 0;
}

.women {
  color: rgb(var(--color-ci-green));
  margin-bottom: .5rem;
}

.men {
  color: rgb(var(--color-ci-red));
  margin-bottom: .5rem;
}

.mixed {
  color: rgb(var(--color-ci-blue));
  margin-bottom: .5rem;
}

.classes {
  list-style-type: none;
  padding: 0;
  font-size: 14px;

  li {
    margin-bottom: 8px;
  }
}

.evaluation_overview {
  display: flex;
  align-items: center;
  font-size: .8rem;
  gap: .5rem;
  margin: 1rem 0;

  &.disabled {
    opacity: .4;
  }

  img {
    width: 100px;
  }
}

.testimonials_inner {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.testimonial {
  background: #e6e6e6;
  border-radius: var(--rounded);
  padding: .3rem 1rem;

  blockquote {
    font-family: Caveat;
    font-size: 1.3rem;
    margin: 0;
  }

  img {
    width: 100px;
    margin: .5rem 0;
  }

  p {
    margin: 0 0 .5rem 0;
    font-size: .8rem;
  }
}

.price {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 18px;
  background: #008a19;
  border-radius: 100px;
  padding: 4px 10px;
  transform: translatey(-50%);
  font-size: 14px;
  outline: 2px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.20);
  color: white;
  font-weight: 700;
}

.organizer_name {
  font-weight: 700;
}

.organizer_top {
  background: var(--bg-image);
  background-size: cover;
  background-position: center center;
  height: 269px;
  width: 100%;
  position: relative;
  border-radius: var(--rounded-lg-t);
  overflow: hidden;
  transition: var(--transition-time);
}

.organizer_logo_container {
  height: 70%;
  width: 70%;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
  }
}

.organizer_title {
  font-size: 1.2rem;
  font-family: 'Montserrat';
  color: var(--contrast-brand);
  background: var(--brand-shade);
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  border-radius: var(--rounded-lg-b);
  transition: var(--transition-time);

  &.active {
    background: white;
    border-radius: 0;
  }
}

.events_overview {
  padding: .75rem 1rem;
  border-radius: var(--rounded-lg-b);
  display: flex;
  gap: .5rem;
  position: absolute;
  bottom: 0;
  color: white;
  width: 100%;
}

.card_content_invisible {

  h4 {
    color: var(--contrast-brand);
    margin-bottom: .5rem;
  }

  ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    margin: 0;
  }
}