@import '../../css-global/break-points.scss';

.article_container { 
  width: 100%;

  --arrow-width: max(3vw, 60px);

  @media screen and (min-width: map-get($bp, lg)) {
    display: flex;
    flex-wrap: wrap;
  }
  
  &.right {
    flex-direction: row-reverse;

    .arrow_container {
      &:before {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        bottom: calc(-1 * var(--arrow-width) / 2);

        @media screen and (min-width: map-get($bp, lg)) {
          left: calc(-1 * var(--arrow-width) / 2);
          top: 17%;
          transform: rotate(45deg);
        }
      }
    }
  }

  &.left {
    .arrow_container{
      &:before {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        bottom: calc(-1 * var(--arrow-width) / 2);

        @media screen and (min-width: map-get($bp, lg)) {
          left: initial;
          right: calc(-1 * var(--arrow-width) / 2);
          top: 17%;
          transform: rotate(45deg);
        }
      }
    }
  }

  > div {
    @media screen and (min-width: map-get($bp, lg)) {
      width: 50%;
    }
  }
}

.img_wrapper {
  height: auto;
  overflow: visible;
  position: relative;
}

.arrow_container {
  position: absolute;
  z-index: 5;
  width: 100%;
  padding-bottom: calc(50% + 1px);

  @media screen and (min-width: map-get($bp, sm)) {
    padding-bottom: calc(40% + 1px);
  }

  @media screen and (min-width: map-get($bp, lg)) {
    padding-bottom: calc(59.583% + 1px);
  }

  &:before {
    content: "";
    height: var(--arrow-width);
    width: var(--arrow-width);
    background: #fff;
    position: absolute;
    z-index: 2;
  }
}

.inner_img_wrapper {
  position: relative;
  padding-bottom: 50%;
  background:rgb(var(--color-gray-2));
  overflow: hidden;

  @media screen and (min-width: map-get($bp, sm)) {
    padding-bottom: 40%;
  }

  @media screen and (min-width: map-get($bp, lg)) {
    padding-bottom: 59.583%;
  }

  img {
    position: absolute;
    width: 100%;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: var(--logo-width-px);

    @media screen and (min-width: map-get($bp, lg)) {
      width: var(--logo-width-percentage);
      max-width: none;
    }
  }

  p {
    line-height: 1.7rem;
  }
}

.content_inner {
  padding: max(5vw, 2rem);
  width: 100%;
  padding: max(5vw, 2rem) max(5vw, 2rem) 3rem max(5vw, 2rem);
  
  @media screen and (min-width: map-get($bp, lg)) {
    padding: max(5vw, 2rem);
  }

  @media screen and (min-width: map-get($bp, lg)) {
    width: 85%;
    padding: 0;
  }
}

.button_link {
  display: inline-block;
  padding: 1rem 2rem;
  border: 2px solid #212121;
  border-radius: 50px;
  color: rgb(var(--color-ci-green));
  text-decoration: none;
  font-weight: 700;
  transition: color .2s, border .2s;
  margin-left: -2px;
  

  @media screen and (min-width: map-get($bp, lg)) {
    font-size: 1.25rem;
  }

  &:hover {
    color: black;
    border: 2px solid rgb(var(--color-ci-green));

    .arrow {
      left: .25rem;
    }
  }
}

.arrow {
  text-decoration: none;
  margin-left: .5rem;
  position: relative;
  left: 0;
  transition: left .2s;
}

.logo_container {

  @media screen and (min-width: map-get($bp, lg)) {
    width: auto;
    max-width: none;
  }
}

.contact_info {
  background: rgb(var(--color-footer-gray));
  color: white;
  font-size: 14px;
  line-height: 23px;
  font-family: "Helvetica Neue", Helvetica;
  padding: 40px 2rem;

  h2 {
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: .25rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    white-space: nowrap;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
    margin-left: 2px;
  }

  a {
    color: rgb(var(--color-ci-green-light));
    text-decoration: none;
    transition: .2s;

    &:hover {
      color: white;
    }
  }
}

.contact_info_inner  {
  display: flex;
  column-gap: 1rem;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
  row-gap: 2rem;

  @media screen and (min-width: map-get($bp, md)) {
    flex-direction: initial;
  }
}

.footer {
  margin-top: 100px;
}

.footer_main {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    text-align: center;
    margin: 1.5rem 0 1rem;
  }
}

.footer_side {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: map-get($bp, md)) {
    justify-content: initial;
  }
}

.footer_logo {
  max-width: min(270px, 70%);
}

.products {
  display: flex;
  column-gap: 2rem;
  row-gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;

  img {
    height: 35px;
  }
}

.login_button {
  background: rgb(var(--color-footer-button));
  padding: 14px 25px;
  border: none;
  border-radius: var(--rounded-full);
  color: white;
}

.cupmanager {
  margin-top: -2px;
}