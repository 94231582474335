
@import '../../css-global/break-points.scss';

.outer_guide_container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  margin: -210px auto 0;
  position: relative;
  z-index: 3;
  
  @media screen and (min-width: map-get($bp, md)) {
    margin: -40px auto auto auto;
  }
}

.inner_guide_container {
  max-width: calc(500px + 4rem);
  padding: 0 2rem 4rem;
  margin: auto;

  @media screen and (min-width: map-get($bp, md)) {
    margin-right: 1rem;
    max-width: auto;
    padding: 0;
    width: auto;
    max-width: initial;
    display: flex;
    gap: 40px;
    margin: 0 2rem;

    > div {
      &:first-child {
        width: 45%;
        max-width: 380px;
      }

      &:last-child {
        flex: 1;
      }
    }
  }

  h2 {
    margin-top: 3rem;
  }
}

.main_header {
  margin-top: 0;
}

.text_html {
  line-height: 1.6;
}

.mobile_card_container {
  margin-bottom: 2rem;
}

@media screen and (min-width: map-get($bp, md)) {
  .mobile_card_container {
    display: none;
  }
}

.mobile_order_button_container {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255, 1);
  box-shadow: var(--box-shadow-inverted);
  padding: 1rem 2rem;

  @media screen and (min-width: map-get($bp, md)) {
    display: none;
  }
}

.desktop_card_container {
  display: none;

  @media screen and (min-width: map-get($bp, md)) {
    margin-top: -2rem;
    display: block;
    margin-bottom: 2rem;

    > div {
      position: sticky;
      top: 80px;
    }
  }
}

.order_button_mobile {
  max-width: calc(500px - 2rem);
  margin: auto;
  font-size: 1.2rem;
  font-weight: bold;
}

.body_button {
  display: none;
}

@media screen and (min-width: map-get($bp, md)) {
  .invitation_body_text {
    margin-bottom: 4rem;
  }

  .body_button {
    display: block;
  }
}