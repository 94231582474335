@import '../../css-global/break-points.scss';

.invitation_card {
  background: white;
  border-radius: var(--rounded-lg);
  box-shadow: var(--box-shadow);
}

.invitation_img {
  border-radius: var(--rounded-lg-t);
  display: block;
  width: 100%;
  border-bottom: 1px solid rgb(220,220,220);
}

.inner_container {
  padding: 0 1rem 1rem;
}

.card_header {
  margin: .75rem 0 .5rem;
}

.card_section_header {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-bottom: 0;
  margin-top: 2rem;
  font-size: 1rem;
}

.meta_data {
  padding: 0 .5rem;
}

.contact_person {

  > div {
    margin-top: .5rem;
  }
}

.header_flex {
  display: flex;
  margin-top: .5rem;
  gap: .5rem;
  align-items: center;

  h4 {
    margin: 0;
  }
}

.contact_website {
  margin-top: .5rem;
}

.order_button_wrapper {
  display: none;

  @media screen and (min-width: map-get($bp, md)) {
    display: block;
    margin-top: 1rem;
  }
}

.card_meta_data {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: .5rem 0;
}