@import '../../css-global/break-points.scss';

.container {
  flex: 1;
  position: relative;
  margin-top: -40px;

  @media screen and (min-width: map-get($bp, md)) {
    margin-top: 0;
  }

  @media screen and (min-width: map-get($bp, lg)) {
    margin-top: -10px;
  }

  @media screen and (min-width: map-get($bp, xl)) {
    margin-top: -20px;
  }

  @media screen and (min-width: map-get($bp, xxl)) {
    margin-top: -40px;
  }
}

.info_boxes {
  article {
    background: var(--bg-color);
    color: var(--text-color);
    margin: 0;
    padding: 3rem 2rem;
    line-height: 1.5;

    @media screen and (min-width: map-get($bp, md)) {
      padding: 6rem 4rem;
      font-size: 18px;
    }

    @media screen and (min-width: map-get($bp, lg)) {
      padding: 6rem;
      font-size: 20px;
    }

    .button  { 
      border-radius: 100px;
      background: var(--text-color);
      color: var(--bg-color);
      padding: 14px 25px;
      text-decoration: none;
    }

    img {
      max-width: 100%;
    }

    h2 {
      margin-top: 0;
    }

    p {
      margin: 0;
    }
  }
}

.page_padding {
  padding: 0 2rem;
}

.no_data {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  position: relative;

  @media screen and (min-width: map-get($bp, md)) {
    min-height: 300px;
  }
  

  > div {
    width: 90vw;
    text-align: center;
    line-height: 1.5;
    margin-top: -60px;
    background: white;
    padding: 1rem 2rem;
    border-radius: var(--rounded-lg);
    box-shadow: var(--box-shadow-card);

    @media screen and (min-width: map-get($bp, md)) {
      margin-top: 0;
      padding: 2rem 4rem;
      width: 80%;
      background: transparent;
      box-shadow: none;
      margin-bottom: 2rem;
    }
  }
}