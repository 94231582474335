.button {
  font-size: 1rem;
  background: rgb(var(--color-ci-green));
  color: white;
  border: 0;
  padding: .5rem 1rem;
  border-radius: var(--rounded-full);
  cursor: pointer;
  text-decoration: none;
  transition: ease 150ms;

  &.inverted {
    background: white;
    color: rgb(var(--color-ci-green));
    
    &:hover {
      color: black;
      background: rgb(var(--color-gray-1));
    }
  }

  &:hover {
    background: white;
    color: black;
  }
}