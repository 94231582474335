.logo_container {
  height: var(--size);
  width: var(--size);
  // padding: 30px;
  display:flex;
  align-items: center;
  justify-content: center;
  // border-radius: var(--rounded-full);
  //background: rgba(255,255,255, .2);

  &.cm_style {
    //background: rgba(255,255,255, .15);

    svg{
      position: relative;
      left: -3px;
    }
  }

  img {
    max-width: 100%;
  }
}