.loading_container {
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: end;
  background: rgb(var(--color-gray-8));
  position:absolute;
  z-index: 100;
  opacity: 1;
  transition: var(--timeout) opacity;

  &.done_enter {
    opacity: 0;
  }

  &.done_exit {
    display: none;
  }
}

.inner_container {
  background: white;
  display: inline-flex;
  border-radius: var(--rounded-full);
  align-items: center;
  gap: 10px;
  margin-bottom: 97px;
  box-shadow: var(--box-shadow-loading);
  padding: .5rem 1.2rem .5rem .5rem;

  img {
    height: 30px;
  }
}